import { Configuration } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfigFrontend: Configuration = {
    auth: {
        clientId: "fa0090a7-9371-4ffd-b9aa-7b4493b176ef",
        authority: "https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb/",
        redirectUri: "https://dvcs-artist-friend.accenture.com/",
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

export const loginRequestFrontend = {
    scopes: ["User.Read"]
};

export const graphConfigFrontend = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
